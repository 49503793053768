// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blue-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/blue.tsx" /* webpackChunkName: "component---src-pages-blue-tsx" */),
  "component---src-pages-contact-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hardware-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/hardware.tsx" /* webpackChunkName: "component---src-pages-hardware-tsx" */),
  "component---src-pages-impressum-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-news-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-powered-by-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/powered-by.tsx" /* webpackChunkName: "component---src-pages-powered-by-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-software-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/software.tsx" /* webpackChunkName: "component---src-pages-software-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-why-eledio-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/why-eledio.tsx" /* webpackChunkName: "component---src-pages-why-eledio-tsx" */),
  "component---src-pages-wiki-tsx": () => import("/home/jenkins/work/workspace/Blueberry_eledio.com_master/src/pages/wiki.tsx" /* webpackChunkName: "component---src-pages-wiki-tsx" */)
}

